
import { PropType, defineComponent } from "vue";

import { showAsCurrency } from "@/services/showAsCurrency";
import type { sfConfType } from "@/types/sfConfType";

export default defineComponent({
  name: "CompareProducts",
  props: {
    contactTitle: String,
    contactFormButton: String,
    apiData: {
      required: true,
      default: {
        campaignId: "",
        user: {
          name: "",
          city: "",
          phone: "",
          email: "",
          message: "",
        },
        filters: {
          creditValue: 200000,
          peopleNumber: 1,
          childrenNumber: 0,
          creditPeriod: 240,
          areaValue: 50,
          interestValue: 1.5,
          incomeValue: 5000,
          cityCode: "GD",
        },
        api: {
          surcharge: {
            installmentFirstTen: 0,
            installmentFirstTenPercent: 0,
            installmentNextTen: 0,
            installmentNextTenPercent: 0,
            installmentTotal: 0,
          },
          regular: {
            installmentFirstTen: 0,
            installmentFirstTenPercent: 0,
            installmentNextTen: 0,
            installmentNextTenPercent: 0,
            installmentTotal: 0,
          },
        },
      },
    },
    creditRestPeriod: Number,
    sfConfig: Object as PropType<sfConfType | null>,
  },
  emits: ["close-modal"],
  setup: function () {
    return {
      showAsCurrency,
    };
  },
});
