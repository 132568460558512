import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "comboContainer" }
const _hoisted_2 = { class: "comboLabel" }
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "comboSlider" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoSign = _resolveComponent("InfoSign")!
  const _component_Slider = _resolveComponent("Slider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.description) + " ", 1),
      (_ctx.tooltipDescription !== '')
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            title: _ctx.tooltipDescription
          }, [
            _createVNode(_component_InfoSign)
          ], 8, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _withDirectives(_createElementVNode("input", {
      type: "number",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localInputValue) = $event)),
      class: "comboInput"
    }, null, 512), [
      [_vModelText, _ctx.localInputValue]
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Slider, {
        modelValue: _ctx.localInputValue,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localInputValue) = $event)),
        tooltips: false,
        min: _ctx.minValue,
        max: _ctx.maxValue,
        step: _ctx.step,
        lazy: true,
        onSlide: _ctx.sliderUpdate
      }, null, 8, ["modelValue", "min", "max", "step", "onSlide"])
    ])
  ]))
}