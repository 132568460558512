import axios from "axios";

const accessSendToken = "e";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.Authorization = `Bearer ${accessSendToken}`;

switch (process.env.NODE_ENV) {
  case "production":
    axios.defaults.baseURL = "https://api.calculator.speedfin.pl/api/v2";
    // axios.defaults.baseURL =
    //   "https://api.calculator.zaufanyposrednik.pl/api/v2";
    break;
  default:
    axios.defaults.baseURL =
      "https://api.calculator.zaufanyposrednik.pl/api/v2";
  //"http://localhost:8083/api/v2";
}
//axios.defaults.baseURL = "https://api.calculator.speedfin.pl/api/v2";
//axios.defaults.baseURL = "https://api.calculator.zaufanyposrednik.pl/api/v2";

export default axios;
