
import { defineComponent } from "vue";
import WebWidget from "./components/WebWidget.vue";

export default defineComponent({
  name: "App",
  components: {
    WebWidget,
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: "full", // mini, full
    },
    secureValue: {
      type: Number,
      required: false,
      default: 350000,
    },
    checkPathUrl: {
      type: String,
      required: false,
      default: "https://widget-mortgage.vercel.app",
    },
    title2: {
      type: String,
      required: false,
      default: "",
    },
    contactTitle: {
      type: String,
      required: false,
      default: "",
    },
    campaignId: {
      type: String,
      required: false,
      default: "default",
    },
  },
});
