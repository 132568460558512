
import { computed, defineComponent } from "vue";
import InfoSign from "@/components/icons/InfoSign.vue";

export default defineComponent({
  name: "InputNumberSimple",
  props: {
    inputValue: {
      type: Number,
      default: 0,
    },
    description: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    tooltipDescription: {
      type: String,
      default: "",
    },
  },
  emits: ["changedVal"],
  components: {
    InfoSign,
  },

  setup: function (props, ctx) {
    const localInputValue = computed({
      get: () => props.inputValue,
      set: (newValue) => {
        ctx.emit("changedVal", Number(newValue));
      },
    });

    return {
      localInputValue,
    };
  },
});
