import { getData, postInternalData, postExternalData } from "./GetData";
import Qs from "qs";

export default {
  getMortgageProducts(
    people: number,
    children: number,
    creditPeriod: number,
    creditValue: number,
    area: number,
    income: number,
    city: string,
    token: string | undefined
  ) {
    const url = "/widget/mortgage/compare";

    const config = {
      params: {
        area,
        people,
        children,
        creditPeriod,
        creditValue,
        income,
        city,
      },
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    return getData(url, config);
  },

  getMortgageProductsMini(queryString: object, token: string | undefined) {
    const url = "/mortgage";

    const paramsSerializer = (params: object) =>
      Qs.stringify(params, { arrayFormat: "brackets" });
    const config = {
      method: "GET",
      params: queryString,
      headers: {
        Authorization: "Bearer " + token,
      },
      paramsSerializer: {
        serialize: paramsSerializer,
      },
    };

    return getData(url, config);
  },

  async sendWidgetData(apiUrl: string, config: any) {
    return postInternalData(apiUrl, config);
  },
};
