import type { sfConfType } from "@/types/sfConfType";

interface LocalConfigType {
  sendUrlPath: string;
  sendFormat: string;
  sendMethod: string;
}

export default class ToUrlParams {
  dataToSend: any;
  config: LocalConfigType;

  constructor(sfConfig: sfConfType) {
    this.config = {
      sendUrlPath: sfConfig.sendUrlPath,
      sendFormat: sfConfig.sendFormat,
      sendMethod: sfConfig.sendMethod,
    };
  }

  addData(dataToSend: any) {
    this.dataToSend = dataToSend;
  }

  send() {
    const url = new URL(this.config.sendUrlPath);

    switch (this.config.sendFormat) {
      case "json":
        // TODO: code block
        break;
      case "string":
        url.searchParams.append(
          "peopleNumber",
          String(this.dataToSend.filters.peopleNumber)
        );
        url.searchParams.append(
          "childrenNumber",
          String(this.dataToSend.filters.childrenNumber)
        );
        url.searchParams.append(
          "creditPeriod",
          String(this.dataToSend.filters.creditPeriod)
        );
        url.searchParams.append(
          "creditValue",
          String(this.dataToSend.filters.creditValue)
        );
        url.searchParams.append(
          "area",
          String(this.dataToSend.filters.areaValue)
        );
        url.searchParams.append(
          "incomeValue",
          String(this.dataToSend.filters.incomeValue)
        );
        url.searchParams.append(
          "city",
          String(this.dataToSend.filters.cityCode)
        );
        break;
      case "array":
        // TODO: code block
        break;
      case "object":
        // TODO: code block
        break;
      default:
      // TODO: code block
    }

    if (this.config.sendMethod === "GET") {
      window.location.href = url.toString();
    }
  }
}
