import { CreateAppFunction, createApp, h } from "vue";
import App from "./App.vue";
import store from "./store";
import Vue3Sanitize from "vue-3-sanitize";
import UUID from "vue3-uuid";
import wrapper from "vue3-webcomponent-wrapper";

const createAppWrapper: CreateAppFunction<Element> = (App) => {
  const app = createApp(App);
  app.use(store);
  app.use(Vue3Sanitize);
  app.use(UUID);

  return app;
};

const webComponent = wrapper(App, createAppWrapper, h);

window.customElements.define("speedfin-widget", webComponent);
