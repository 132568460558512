
import {
  Ref,
  computed,
  defineComponent,
  getCurrentInstance,
  nextTick,
  onMounted,
  ref,
  toRaw,
} from "vue";

import type { CustomWindow } from "@/types/window";
import type { sfConfType } from "@/types/sfConfType";
import { showAsCurrency } from "@/services/showAsCurrency";
import { formToQuery } from "../services/DataToParams";
import FetchData from "../services/FetchDataService";
import { ExporterFactory } from "../services/exporterFactory";

import ComboInputSlider from "./ui/ComboInputSlider.vue";
import InputSelect from "./ui/InputSelect.vue";
import InputNumberSimple from "./ui/InputNumberSimple.vue";
import CompareProducts from "./CompareProducts.vue";
import ContactForm from "./ContactForm.vue";

declare let window: CustomWindow;

export default defineComponent({
  name: "WebWidget",
  props: {
    mode: {
      type: String,
      required: false,
      default: "full", // mini, full
    },
    title: {
      type: String,
      required: true,
      default: "",
    },
    contactTitle: {
      type: String,
      required: true,
      default: "",
    },
    campaignId: {
      type: String,
      required: true,
      default: "",
    },
  },
  components: {
    ComboInputSlider,
    InputSelect,
    InputNumberSimple,
    ContactForm,
    CompareProducts,
  },
  setup: function (props) {
    const sfConfig: Ref<sfConfType | null> = ref(null);
    const peopleNumberVal = ref(1);
    const peopleNumberOptions = [
      { id: 1, title: "1" },
      { id: 2, title: "2" },
      { id: 3, title: "3" },
      { id: 4, title: "4" },
      { id: 5, title: "5" },
      { id: 6, title: "6" },
      { id: 7, title: "7" },
      { id: 8, title: "8" },
      { id: 9, title: "9" },
    ];
    const childrenNumberVal = ref(0);
    const childrenNumberOptions = [
      { id: 0, title: "0" },
      { id: 1, title: "1" },
      { id: 2, title: "2" },
      { id: 3, title: "3" },
      { id: 4, title: "4" },
      { id: 5, title: "5" },
      { id: 6, title: "6" },
      { id: 7, title: "7" },
      { id: 8, title: "8" },
    ];
    const periodVal = ref(30);
    const creditVal = ref(200000);
    const interestVal = ref(1.5);
    const areaVal = ref(50);
    const creditLimitVal = ref("200 tys.");
    const incomeVal = ref(5000);

    const cityVal = ref("GD");
    const cityOptions = [
      { id: "GD", title: "Gdańsk" },
      { id: "KR", title: "Kraków" },
      { id: "PO", title: "Poznań" },
      { id: "WA", title: "Warszawa" },
      { id: "DW", title: "Wrocław" },
      { id: "OTHER", title: "inne" },
    ];

    const modal = ref(false);
    const inputForceUpdate = ref(1);

    const isLoading = ref(false);
    const showResults = ref(false);
    const creditRestPeriod = ref(20);

    const instSurFirstTen = ref(0);
    const instSurFirstTenPercent = ref(0);
    const instSurNextTen = ref(0);
    const instSurNextTenPercent = ref(0);
    const instSurTotal = ref(0);

    const instRegFirstTen = ref(0);
    const instRegFirstTenPercent = ref(0);
    const instRegNextTen = ref(0);
    const instRegNextTenPercent = ref(0);
    const instRegTotal = ref(0);

    const getNumFromStr = (value: string) => {
      let strValue = String(value);
      strValue = strValue.replace(/\D/g, "");
      return parseInt(strValue);
    };

    const dataToSend = ref({
      campaignId: "",
      user: {
        name: "",
        city: "",
        phone: "",
        email: "",
        message: "",
      },
      filters: {
        creditValue: 200000,
        peopleNumber: 1,
        childrenNumber: 0,
        creditPeriod: 240,
        areaValue: 50,
        interestValue: 1.5,
        incomeValue: 5000,
        cityCode: "GD",
      },
      api: {
        surcharge: {
          installmentFirstTen: 0,
          installmentFirstTenPercent: 0,
          installmentNextTen: 0,
          installmentNextTenPercent: 0,
          installmentTotal: 0,
        },
        regular: {
          installmentFirstTen: 0,
          installmentFirstTenPercent: 0,
          installmentNextTen: 0,
          installmentNextTenPercent: 0,
          installmentTotal: 0,
        },
      },
    });

    const showContact = () => {
      if (sfConfig.value === null) {
        console.log("brak pliku konfiguracyjnego");
        return false;
      }

      if (
        sfConfig.value.sendType === "internalSF" ||
        sfConfig.value.sendType === "internalForm"
      ) {
        modal.value = !modal.value;
      } else {
        const externalExport = ExporterFactory.createExporter(sfConfig.value);
        externalExport.addData(dataToSend.value);
        externalExport.send();
      }
    };

    const closeContact = () => {
      modal.value = !modal.value;
    };

    const instance = getCurrentInstance();
    const sanitize = instance?.appContext.config.globalProperties.$sanitize;

    const changedPeopleNumberVal = (value: number) => {
      peopleNumberVal.value = value;
      switch (value) {
        case 1:
          creditLimitVal.value = "200 tys.";
          break;
        case 2:
          creditLimitVal.value = "400 tys.";
          break;
        case 3:
          creditLimitVal.value = "450 tys.";
          break;
        case 4:
          creditLimitVal.value = "500 tys.";
          break;
        default:
          creditLimitVal.value = "600 tys.";
      }
    };
    const changedChildrenNumberVal = (value: number) => {
      childrenNumberVal.value = value;
      switch (value) {
        case 0:
          interestVal.value = 1.5;
          break;
        case 1:
          interestVal.value = 1;
          break;
        case 2:
          interestVal.value = 0.5;
          break;
        default:
          interestVal.value = 0;
      }
    };
    const changedPeriodVal = (value: number) => {
      periodVal.value = value;
    };
    const changedCreditVal = (value: number) => {
      creditVal.value = value;
    };
    const changedCityVal = (value: string) => {
      cityVal.value = value;
    };
    const changedAreaVal = (value: number) => {
      areaVal.value = value;
    };
    const changedIncomeVal = (value: number) => {
      incomeVal.value = value;
    };

    const mortgageCheckClick = (e: MouseEvent) => {
      if (e.target !== null) {
        (e.target as HTMLElement).blur();
      }
      mortgageCheck();
    };

    const mortgageCheck = () => {
      isLoading.value = true;
      nextTick(() => {
        const people = getNumFromStr(String(peopleNumberVal.value));
        const children = getNumFromStr(String(childrenNumberVal.value));
        const period = 12 * getNumFromStr(String(periodVal.value));
        const credit = getNumFromStr(String(creditVal.value));
        const area = getNumFromStr(String(areaVal.value));
        const income = getNumFromStr(String(incomeVal.value));
        const city = String(cityVal.value);
        creditRestPeriod.value = periodVal.value - 10;
        const result = FetchData.getMortgageProducts(
          people,
          children,
          period,
          credit,
          area,
          income,
          city,
          sfConfig.value?.readToken
        );

        result.then((resObj) => {
          if (resObj.status === 200) {
            //resultList.value = resObj.data;
            // console.log("odebrane===", resObj);
            const promFirstPeriod = resObj.data.promotion.periodResults[0];
            const promSecondPeriod = resObj.data.promotion.periodResults[1];

            const regFirstPeriod = resObj.data.regular.periodResults[0];
            const regSecondPeriod = resObj.data.regular.periodResults[1];

            dataToSend.value.filters = {
              peopleNumber: toRaw(peopleNumberVal.value),
              childrenNumber: toRaw(childrenNumberVal.value),
              creditPeriod: toRaw(periodVal.value),
              creditValue: toRaw(creditVal.value),
              areaValue: toRaw(areaVal.value),
              interestValue: toRaw(interestVal.value),
              incomeValue: toRaw(incomeVal.value),
              cityCode: toRaw(cityVal.value),
            };

            // TODO: dopisac zwrotke z api
            //console.log("=====", resObj.data);
            const iPromTotal =
              promFirstPeriod.interestValue -
              promFirstPeriod.surchargeValue +
              promSecondPeriod.interestValue;

            dataToSend.value.api = {
              surcharge: {
                installmentFirstTen:
                  promFirstPeriod.monthlyPaymentValue.toFixed(),
                installmentFirstTenPercent: promFirstPeriod.interestRate,
                installmentNextTen:
                  promSecondPeriod.monthlyPaymentValue.toFixed(),
                installmentNextTenPercent: promSecondPeriod.interestRate,
                installmentTotal: Number(iPromTotal.toFixed()),
              },
              regular: {
                installmentFirstTen:
                  regFirstPeriod.monthlyPaymentValue.toFixed(),
                installmentFirstTenPercent: regFirstPeriod.interestRate,
                installmentNextTen:
                  regSecondPeriod.monthlyPaymentValue.toFixed(),
                installmentNextTenPercent: regSecondPeriod.interestRate,
                installmentTotal:
                  resObj.data.regular.totalInterestValue.toFixed(),
              },
            };

            showResults.value = true;
            isLoading.value = false;
          }
        });
      });
    };

    const resultInfo = computed(() => {
      return sanitize(sfConfig.value?.resultInfo);
    });

    const labelPeopleNumber = computed(() => {
      if (
        typeof sfConfig.value?.labelPeopleNumber === "undefined" ||
        sfConfig.value?.labelPeopleNumber === ""
      ) {
        return "ILOŚĆ OSÓB";
      }
      return sanitize(sfConfig.value?.labelPeopleNumber);
    });

    const labelChildrenNumber = computed(() => {
      if (
        typeof sfConfig.value?.labelChildrenNumber === "undefined" ||
        sfConfig.value?.labelChildrenNumber === ""
      ) {
        return "LICZBA DZIECI";
      }
      return sanitize(sfConfig.value?.labelChildrenNumber);
    });

    const labelPeriod = computed(() => {
      if (
        typeof sfConfig.value?.labelPeriod === "undefined" ||
        sfConfig.value?.labelPeriod === ""
      ) {
        return "OKRES SPŁATY";
      }
      return sanitize(sfConfig.value?.labelPeriod);
    });

    const labelCredit = computed(() => {
      if (
        typeof sfConfig.value?.labelCredit === "undefined" ||
        sfConfig.value?.labelCredit === ""
      ) {
        return "KWOTA KREDYTU";
      }
      return sanitize(sfConfig.value?.labelCredit);
    });

    const labelArea = computed(() => {
      if (
        typeof sfConfig.value?.labelArea === "undefined" ||
        sfConfig.value?.labelArea === ""
      ) {
        return "METRAŻ NIERUCHOMOŚCI [m2]";
      }
      return sanitize(sfConfig.value?.labelArea);
    });

    const labelInterest = computed(() => {
      if (
        typeof sfConfig.value?.labelInterest === "undefined" ||
        sfConfig.value?.labelInterest === ""
      ) {
        return "PREFERENCYJNE %";
      }
      return sanitize(sfConfig.value?.labelInterest);
    });

    const labelIncome = computed(() => {
      if (
        typeof sfConfig.value?.labelIncome === "undefined" ||
        sfConfig.value?.labelIncome === ""
      ) {
        return "ŚREDNI DOCHÓD";
      }
      return sanitize(sfConfig.value?.labelIncome);
    });

    const labelCity = computed(() => {
      if (
        typeof sfConfig.value?.labelCity === "undefined" ||
        sfConfig.value?.labelCity === ""
      ) {
        return "LOKALIZACJA NIERUCHOMOŚCI";
      }
      return sanitize(sfConfig.value?.labelCity);
    });

    const tooltipPeopleNumber = computed(() => {
      if (
        typeof sfConfig.value?.tooltipPeopleNumber === "undefined" ||
        sfConfig.value?.tooltipPeopleNumber === ""
      ) {
        return "Wszystkie osoby w gospodarstwie domowym, także dzieci";
      }
      return sanitize(sfConfig.value?.tooltipPeopleNumber);
    });

    const tooltipPeriodVal = computed(() => {
      if (
        typeof sfConfig.value?.tooltipPeriod === "undefined" ||
        sfConfig.value?.tooltipPeriod === ""
      ) {
        return "Okres spłaty";
      }
      return sanitize(sfConfig.value?.tooltipPeriod);
    });

    const tooltipAreaVal = computed(() => {
      if (
        typeof sfConfig.value?.tooltipArea === "undefined" ||
        sfConfig.value?.tooltipArea === ""
      ) {
        return "Powierzchnia nieruchomości";
      }
      return sanitize(sfConfig.value?.tooltipArea);
    });

    const tooltipIncome = computed(() => {
      if (
        typeof sfConfig.value?.tooltipIncome === "undefined" ||
        sfConfig.value?.tooltipIncome === ""
      ) {
        return "Średni mc dochód netto gospodarstwa domowego";
      }
      return sanitize(sfConfig.value?.tooltipIncome);
    });

    const textCheckBtn = computed(() => {
      if (
        typeof sfConfig.value?.textCheckBtn === "undefined" ||
        sfConfig.value?.textCheckBtn === ""
      ) {
        return "SPRAWDŹ";
      }
      return sanitize(sfConfig.value?.textCheckBtn);
    });

    const textResultBtn = computed(() => {
      if (
        typeof sfConfig.value?.textResultBtn === "undefined" ||
        sfConfig.value?.textResultBtn === ""
      ) {
        return "Umów się";
      }
      return sanitize(sfConfig.value?.textResultBtn);
    });

    const additionalInfo = computed(() => {
      return sanitize(sfConfig.value?.additionalInfo, {
        allowedTags: ["div", "span", "p"],
        allowedAttributes: {
          div: ["class"],
          span: ["class"],
          p: ["class"],
        },
      });
    });

    onMounted(() => {
      const widgetConfig = window.widgetConfig;
      if (typeof widgetConfig !== "undefined") {
        sfConfig.value = widgetConfig;
      }
      dataToSend.value.campaignId = props.campaignId;
    });

    return {
      sfConfig,
      peopleNumberVal,
      peopleNumberOptions,
      childrenNumberVal,
      childrenNumberOptions,
      periodVal,
      creditVal,
      interestVal,
      areaVal,
      creditLimitVal,
      cityVal,
      cityOptions,
      incomeVal,
      changedPeopleNumberVal,
      changedChildrenNumberVal,
      changedPeriodVal,
      changedCreditVal,
      changedCityVal,
      changedAreaVal,
      changedIncomeVal,
      mortgageCheckClick,
      mortgageCheck,
      showResults,
      creditRestPeriod,
      isLoading,

      instSurFirstTen,
      instSurFirstTenPercent,
      instSurNextTen,
      instSurNextTenPercent,
      instSurTotal,
      instRegFirstTen,
      instRegFirstTenPercent,
      instRegNextTen,
      instRegNextTenPercent,
      instRegTotal,

      showContact,
      closeContact,
      modal,
      showAsCurrency,

      dataToSend,

      resultInfo,
      inputForceUpdate,
      labelPeopleNumber,
      labelChildrenNumber,
      labelPeriod,
      labelCredit,
      labelArea,
      labelInterest,
      labelIncome,
      labelCity,
      tooltipPeopleNumber,
      tooltipPeriodVal,
      tooltipAreaVal,
      tooltipIncome,
      textCheckBtn,
      textResultBtn,
      additionalInfo,
    };
  },
});
